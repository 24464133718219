// src/Dashboard.js
import React from 'react';
import './Seller_profile.css'; // Import the unique CSS file

const Dashborads = () => {
    const orders = [
        { id: '#001', date: '2024-09-01', revenue: 150.00, status: 'Completed' },
        { id: '#002', date: '2024-09-02', revenue: 200.00, status: 'Pending' },
        { id: '#003', date: '2024-09-03', revenue: 50.00, status: 'Completed' },
        // Add more orders as needed
    ];

    const totalRevenue = orders.reduce((acc, order) => acc + order.revenue, 0);
    const totalOrders = orders.length;
    const averageOrderValue = (totalRevenue / totalOrders).toFixed(2);
    const totalPending = orders.filter(order => order.status === 'Pending').length;

    return (
        <div className="flex flex-col min-h-screen">
            {/* Header */}
            <header className="bg-white shadow">
                <div className="container mx-auto px-4 py-4">
                    <h1 className="text-2xl font-bold text-gray-800">Seller Dashboard</h1>
                </div>
            </header>

            {/* Main Content */}
            <main className="flex-grow container mx-auto px-4 py-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    {/* Card 1: Total Revenue */}
                    <div className="dashboard-card">
                        <h2 className="text-lg font-semibold text-gray-700">Total Revenue</h2>
                        <p className="text-3xl font-bold text-green-600">${totalRevenue.toFixed(2)}</p>
                    </div>
                    {/* Card 2: Total Orders */}
                    <div className="dashboard-card">
                        <h2 className="text-lg font-semibold text-gray-700">Total Orders</h2>
                        <p className="text-3xl font-bold text-blue-600">{totalOrders}</p>
                    </div>
                    {/* Card 3: Average Order Value */}
                    <div className="dashboard-card">
                        <h2 className="text-lg font-semibold text-gray-700">Average Order Value</h2>
                        <p className="text-3xl font-bold text-yellow-600">${averageOrderValue}</p>
                    </div>
                    {/* Card 4: Total Pending Orders */}
                    <div className="dashboard-card">
                        <h2 className="text-lg font-semibold text-gray-700">Pending Orders</h2>
                        <p className="text-3xl font-bold text-red-600">{totalPending}</p>
                    </div>
                </div>

                {/* Order Details Table */}
                <div className="mt-6 bg-white rounded-lg shadow overflow-hidden">
                    <h2 className="text-lg font-semibold p-4 text-gray-800">Order Details</h2>
                    <table className="dashboard-table">
                        <thead className="dashboard-table-header">
                            <tr>
                                <th className="dashboard-table-cell">Order ID</th>
                                <th className="dashboard-table-cell">Date</th>
                                <th className="dashboard-table-cell">Revenue</th>
                                <th className="dashboard-table-cell">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order) => (
                                <tr key={order.id} className="dashboard-table-row">
                                    <td className="dashboard-table-cell">{order.id}</td>
                                    <td className="dashboard-table-cell">{order.date}</td>
                                    <td className="dashboard-table-cell">${order.revenue.toFixed(2)}</td>
                                    <td className="dashboard-table-cell">{order.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </main>
        </div>
    );
};

export default Dashborads;
