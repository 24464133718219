import React, { useState } from 'react';
import './Page.css'; // Unique CSS file for styling
import { Link } from 'react-router-dom';

const SellerRegistrationOnePage = () => {
  const [formData, setFormData] = useState({
    storeName: '',
    ownerName: '',
    email: '',
    phone: '',
    address: '',
    businessLicense: '',
    bankAccount: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add validation logic here
    alert("Seller Registration Successful!");
  };

  return (
    <div className="seller-reg-page-container">
      <div className="seller-reg-box-container">
        <h2 className="seller-reg-title">Seller Registration</h2>
        <form onSubmit={handleSubmit} className="seller-reg-form">
          <div className="seller-reg-input-group">
            <div className="seller-reg-input-box">
              <input
                type="text"
                name="storeName"
                value={formData.storeName}
                onChange={handleChange}
                required
              />
              <label>Store Name</label>
            </div>
            <div className="seller-reg-input-box">
              <input
                type="text"
                name="ownerName"
                value={formData.ownerName}
                onChange={handleChange}
                required
              />
              <label>Owner Name</label>
            </div>
          </div>
          
          <div className="seller-reg-input-group">
            <div className="seller-reg-input-box">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <label>Email</label>
            </div>
            <div className="seller-reg-input-box">
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              <label>Phone</label>
            </div>
          </div>

          <div className="seller-reg-input-box">
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
            <label>Address</label>
          </div>

          <div className="seller-reg-input-group">
            <div className="seller-reg-input-box">
              <input
                type="text"
                name="businessLicense"
                value={formData.businessLicense}
                onChange={handleChange}
                required
              />
              <label>Business License</label>
            </div>
            <div className="seller-reg-input-box">
              <input
                type="text"
                name="bankAccount"
                value={formData.bankAccount}
                onChange={handleChange}
                required
              />
              <label>Bank Account</label>
            </div>
          </div>

         <Link to="/profile" className="seller-reg-submit-btn">
  Register
</Link>
        </form>

        {/* <div className="seller-reg-login-link">
          Already have an account? <Link to="/seller-login" className="seller-reg-login-link-btn">Login</Link>
        </div> */}
      </div>
    </div>
  );
};

export default SellerRegistrationOnePage;




//    <nav className="navbar navbar-expand-lg navbar-light bg-light py-3 sticky-top">
//     <div className="container">
//         <NavLink className="navbar-brand fw-bold fs-4 px-2" to="/"><img src={"logo"} alt="" width={"250px"} /></NavLink>
//         <button className="navbar-toggler mx-2" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//             <span className="navbar-toggler-icon"></span>
//         </button>

//         <div className="collapse navbar-collapse" id="navbarSupportedContent">
//             <ul className="navbar-nav m-auto my-2 text-center">
//                 <li className="nav-item">
//                     <NavLink className="nav-link" to="/">Home </NavLink>
//                 </li>
//                 <li className="nav-item">
//                     <NavLink className="nav-link" to="/product">Products</NavLink>
//                 </li>
//                 <li className="nav-item">
//                     <NavLink className="nav-link" to="/about">About</NavLink>
//                 </li>
//                 <li className="nav-item">
//                     <NavLink className="nav-link" to="/contact">Contact</NavLink>
//                 </li>
//             </ul>
//             <div className="buttons text-center">
//                 <NavLink to="/supplier_reg" className="btn btn-outline-dark m-2"><i className="fa fa-sign-in-alt mr-1"> Supplier</i> </NavLink>
//                 <NavLink to="/login" className="btn btn-outline-dark m-2"><i className="fa fa-sign-in-alt mr-1"></i> </NavLink>
//                 <NavLink to="/register" className="btn btn-outline-dark m-2"><i className="fa fa-user-plus mr-1"></i> </NavLink>
//                 <NavLink to="/cart" className="btn btn-outline-dark m-2"><i className="fa fa-cart-shopping mr-1"></i>  ({state.length}) </NavLink>
//             </div>
//         </div>


//     </div>
// </nav>
  