import React from "react";
import { Link } from "react-router-dom";
import { Footer, Navbar } from "../components";
import "./Page.css"

const Login = () => {
  return (
    <>
      <Navbar />
      {/* <div className="container my-3 py-3">
        <h1 className="text-center">Login</h1>
        <hr />
        <div class="row my-4 h-100">
          <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
            <form>
              <div class="my-3">
                <label for="display-4">Email address</label>
                <input
                  type="email"
                  class="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                />
              </div>
              <div class="my-3">
                <label for="floatingPassword display-4">Password</label>
                <input
                  type="password"
                  class="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                />
              </div>
              <div className="my-3">
                <p>New Here? <Link to="/register" className="text-decoration-underline text-info">Register</Link> </p>
              </div>
              <div className="text-center">
                <button class="my-2 mx-auto btn btn-dark" type="submit" disabled>
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div> */}
      <>
      <section id="login_page">
  <div className="container mt-5" >
    <div className="left">
      <div className="welcome-message">
        <h1>Welcome!</h1>
        <p>We're glad to have you back.</p>
      </div>
    </div>
    <div className="right">
      <div className="login-box">
        <div className="login-title">
          <h2>Login</h2>
        </div>
        <form action="/login" method="POST">
          <div className="input-box">
            <label htmlFor="Email">Email</label>
            <input type="text" id="username" name="username" required="" />
          </div>
          
          <div className="input-box">
            <label htmlFor="password">Password:</label>
            <input type="password" id="password" name="password" required="" />
          </div>
          <button type="submit">Login</button>
        </form>
        {/* Forgot password and sign up links */}
        <div className="login-options">
          <a href="/forgot-password">Forgot password?</a>
          <a href="/register">Don't have an account? Sign up</a>
        </div>
        {/* Divider with "or" in the middle */}
        <div className="divider">or</div>
        {/* Social login icons */}
        <div className="social-login">
          <i className="fa-brands fa-google" />
          <i className="fa-brands fa-facebook" />
          <i className="fa-brands fa-twitter" />
          <i className="fa-brands fa-apple" />
        </div>
      </div>
    </div>
  </div>
  {/* Font Awesome CDN Script */}
  </section>
</>

      <Footer />
    </>
  );
};

export default Login;
