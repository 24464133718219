import React from "react";
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import banner from "../assets/images/banner.png"
import midbanner from "../assets/images/midbanner.png"
const Home = () => {
  return (
    <>
       
     
       {/* <Carousel>
      <Carousel.Item interval={1000}>
        <img
          className="d-block w-100"
          src={banner} // Replace with your image path
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={midbanner}// Replace with your image path
          alt="Second slide"
        />
        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src={banner} // Replace with your image path
          alt="Third slide"
        />
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel> */}
       <div className="containerss">
  <div className="slider">
    <div className="slide slide1">
      {/* <div className="caption">
        <h2>Contrasting Crescents</h2>
        <p>
          In this view, Saturn’s icy moon Rhea passes in front of Titan as seen
          by NASA’s Cassini spacecraft. Some of the differences between the two
          large moons are readily apparent. While Rhea is a heavily-cratered,
          airless world, Titan’s nitrogen-rich atmosphere is even thicker than
          Earth’s.
        </p>
        <br />
        <p>
          This natural color image was taken in visible light with the Cassini
          narrow-angle camera on Nov. 19, 2009, at a distance of approximately
          713,300 miles (1,148,000 kilometers) from Rhea.
        </p>
        <p>
          <a href="https://solarsystem.nasa.gov/resources/17820/contrasting-crescents/">
            See More
          </a>
        </p>
      </div> */}
    </div>
    <div className="slide slide2">
      {/* <div className="caption">
        <h2>Group Portrait</h2>
        <p>
          On July 29, 2011, Cassini captured five of Saturn’s moons in a single
          frame with its narrow-angle camera. This is a full-color look at a
          view that was originally published in September 2011 (see PIA14573).
        </p>
        <br />
        <p>
          Moons visible in this view: Janus (111 miles, or 179 kilometers
          across) is on the far left; Pandora (50 miles, or 81 kilometers
          across) orbits just beyond the thin F ring near the center of the
          image; brightly reflective Enceladus (313 miles, or 504 kilometers
          across) appears above center; Saturn's second largest moon, Rhea (949
          miles, or 1,528 kilometers across), is bisected by the right edge of
          the image; and the smaller moon Mimas (246 miles, or 396 kilometers
          across) is seen just to the left of Rhea.
        </p>
        <p>
          <a href="https://solarsystem.nasa.gov/resources/17848/group-portrait/">
            See More
          </a>
        </p>
      </div> */}
    </div>
    <div className="slide slide3">
      {/* <div className="caption">
        <h2>Planet Six</h2>
        <p>
          This was Cassini’s view from orbit around Saturn on Jan. 2, 2010. In
          this image, the rings on the night side of the planet have been
          brightened significantly to more clearly reveal their features. On the
          day side, the rings are illuminated both by direct sunlight, and by
          light reflected off Saturn’s cloud tops.
        </p>
        <p>
          This natural-color view is a composite of images taken in visible
          light with the Cassini spacecraft’s narrow-angle camera at a distance
          of approximately 1.4 million miles (2.3 million kilometers) from
          Saturn.
        </p>
        <p>
          <a href="https://solarsystem.nasa.gov/resources/17845/planet-six/">
            See More
          </a>
        </p>
      </div> */}
    </div>
    <div className="slide slide4">
      {/* <div className="caption">
        <h2>Peering Through Titan's Haze</h2>
        <p>
          This composite image shows an infrared view of Saturn's moon Titan
          from NASA's Cassini spacecraft, acquired during the mission's "T-114"
          flyby on Nov. 13, 2015. The spacecraft's visual and infrared mapping
          spectrometer (VIMS) instrument made these observations, in which blue
          represents wavelengths centered at 1.3 microns, green represents 2.0
          microns, and red represents 5.0 microns. A view at visible wavelengths
          (centered around 0.5 microns) would show only Titan's hazy atmosphere
          (as in PIA14909). The near-infrared wavelengths in this image allow
          Cassini's vision to penetrate the haze and reveal the moon's surface.
        </p>
        <p>
          During this Titan flyby, the spacecraft's closest-approach altitude
          was 6,200 miles (10,000 kilometers), which is considerably higher than
          those of typical flybys, which are around 750 miles (1,200
          kilometers). The high flyby allowed VIMS to gather moderate-resolution
          views over wide areas (typically at a few kilometers per pixel).
        </p>
        <p>
          <a href="https://solarsystem.nasa.gov/resources/208/peering-through-titans-haze/">
            See More
          </a>
        </p>
      </div> */}
    </div>
    <div className="slide slide5">
      {/* <div className="caption">
        <h2>Postcard from the Ring Plane</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
        <p>
          <a href="https://solarsystem.nasa.gov/resources/17845/planet-six/">
            See More
          </a>
        </p>
      </div> */}
    </div>
  </div>
</div>
        {/* <div className="hero border-1 pb-3">
          <div className="card bg-dark text-white border-0 mx-3">
            <img
              className="card-img img-fluid"
              src={banner}
              alt="Card"
              height={500}
            />
          
            <div className="card-img-overlay d-flex align-items-center">
              <div className="container">
                <h5 className="card-title fs-1 text fw-lighter"> New Season Arrivals</h5>
                <p className="card-text fs-5 d-none d-sm-block ">
                  This is a wider card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </p>
              </div>
            </div>
          </div>
        </div>*/}




    </>
  );
};

export default Home;
