import React, { useState } from 'react';
import '../index.css'; // Import the CSS for styling
import Dashboard from '../Sell/Dashborads'; // Import the Dashboard component
import Orders from '../Sell/Seller_order'; // Import the Orders component
import Account from '../Sell/Seller_account'; // Import the Account component
import Product from "../Sell/Seller_product";
import Navbar from  "../Sell/Seller_nav"

function Loader() {
  const [activeSection, setActiveSection] = useState('dashboard'); // To track the current section
  const [notifications] = useState(5);
  const [products] = useState([
    { id: 1, name: 'Product 1', price: '$10', stock: 20 },
    { id: 2, name: 'Product 2', price: '$20', stock: 15 },
    { id: 3, name: 'Product 3', price: '$30', stock: 5 },
  ]);

  // Function to render different sections based on the active state
  const renderContent = () => {
    switch (activeSection) {
      case 'dashboard':
        return <Dashboard />;
      case 'products':
        return  <Product />
        // (
        //   <section className="product-list">
        //     <h2>Your Products</h2>
        //     <table>
        //       <thead>
        //         <tr>
        //           <th>ID</th>
        //           <th>Name</th>
        //           <th>Price</th>
        //           <th>Stock</th>
        //         </tr>
        //       </thead>
        //       <tbody>
        //         {products.map((product) => (
        //           <tr key={product.id}>
        //             <td>{product.id}</td>
        //             <td>{product.name}</td>
        //             <td>{product.price}</td>
        //             <td>{product.stock}</td>
        //           </tr>
        //         ))}
        //       </tbody>
        //     </table>
        //   </section>
        // );
      case 'orders':
        return <Orders />;
      case 'account':
        return <Account />;
      default:
        return <Dashboard />;
    }
  };

  return (

    <>
 
    <div className="seller-panel">
   

      {/* Sidebar */}
      <aside className="sidebar">
        <h2>Seller Panel</h2>
        <nav>
          <ul>
            <li onClick={() => setActiveSection('dashboard')}>Dashboard</li>
            <li onClick={() => setActiveSection('products')}>Products</li>
            <li onClick={() => setActiveSection('orders')}>Orders</li>
            <li onClick={() => setActiveSection('account')}>Account</li>
          </ul>
        </nav>
      </aside>

      {/* Main content */}
      <div className="main-content">
        {/* Header */}
          <header>
            <h1>{activeSection.charAt(0).toUpperCase() + activeSection.slice(1)}</h1>
          </header>

        {/* Dynamic Content */}
        <div className="section-content">{renderContent()}</div>
      </div>
    </div>
    </>
  );
}

export default Loader;
