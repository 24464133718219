// src/components/VendorOrderPage.js
import React from 'react';
import './Seller_profile.css'; // Import the custom CSS file

const VendorOrderPage = () => {
    const orders = [
        { id: '#001', customer: 'John Doe', date: '2024-09-01', revenue: 150.00, status: 'Completed', imageUrl: 'https://via.placeholder.com/50' },
        { id: '#002', customer: 'Jane Smith', date: '2024-09-02', revenue: 200.00, status: 'Pending', imageUrl: 'https://via.placeholder.com/50' },
        { id: '#003', customer: 'David Johnson', date: '2024-09-03', revenue: 50.00, status: 'Processing', imageUrl: 'https://via.placeholder.com/50' },
        // Add more orders as needed
    ];

    return (
        <div className="full-vendor-order-page-layout">
            {/* Header */}
            <header className="vendor-order-page-header">
                <div className="vendor-header-container">
                    <h1 className="vendor-order-page-title">Vendor Panel - Orders</h1>
                    <div className="vendor-header-actions">
                        <button className="vendor-create-order-btn">Create New Order</button>
                        <input type="text" className="vendor-search-orders-input" placeholder="Search orders..." />
                    </div>
                </div>
            </header>

            {/* Main Content */}
            <main className="vendor-order-list-section">
                <div className="vendor-table-wrapper">
                    <table className="vendor-order-table">
                        <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Customer</th>
                                <th>Customer Image</th> {/* New Image Column */}
                                <th>Date</th>
                                <th>Revenue</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order) => (
                                <tr key={order.id}>
                                    <td>{order.id}</td>
                                    <td>{order.customer}</td>
                                    <td>
                                        <img 
                                            src={order.imageUrl} 
                                            alt={order.customer} 
                                            className="vendor-customer-image" 
                                        />
                                    </td> {/* Display Customer Image */}
                                    <td>{order.date}</td>
                                    <td>${order.revenue.toFixed(2)}</td>
                                    <td>{order.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </main>
        </div>
    );
};

export default VendorOrderPage;
