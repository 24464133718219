import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/action";
// import { useRef } from 'react';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import bed from '../assets/images/bed.png'
const Products = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(data);
  const [loading, setLoading] = useState(false);
  let componentMounted = true;

  const dispatch = useDispatch();

  const addProduct = (product) => {
    dispatch(addCart(product));
  };

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      const response = await fetch("https://fakestoreapi.com/products/");
      if (componentMounted) {
        setData(await response.clone().json());
        setFilter(await response.json());
        setLoading(false);
      }

      return () => {
        componentMounted = false;
      };
    };

    getProducts();
  }, []);

  const Loading = () => {
    return (
      <>
        <div className="col-12 py-5 text-center">
          <Skeleton height={40} width={560} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
      </>
    );
  };


  const filterProduct = (cat) => {
    const updatedList = data.filter((item) => item.category === cat);
    setFilter(updatedList);
  };

  const ShowProducts = () => {
    return (
      <>
        <div className="buttons-container">
          <div className="buttons text-center py-5">
            <button
              className="btn btn-outline-dark btn-sm m-2"
              onClick={() => setFilter(data)}
            >
              Massage Chairs and Beds
            </button>
            <button
              className="btn btn-outline-dark btn-sm m-2"
              onClick={() => setFilter(data)}
            >
              Legs and Foot Massagers
            </button>
            <button
              className="btn btn-outline-dark btn-sm m-2"
              onClick={() => setFilter(data)}
            >
              Only Foot Massagers
            </button>
            <button
              className="btn btn-outline-dark btn-sm m-2"
              onClick={() => setFilter(data)}
            >
              Premium Leg & Foot Massagers
            </button>
            <button
              className="btn btn-outline-dark btn-sm m-2"
              onClick={() => setFilter(data)}
            >
              Back Spine & Neck Massagers
            </button>
            <button
              className="btn btn-outline-dark btn-sm m-2"
              onClick={() => setFilter(data)}
            >
              Body Massagers
            </button>
            <button
              className="btn btn-outline-dark btn-sm m-2"
              onClick={() => setFilter(data)}
            >
              Lifelong Massagers & Personal Care
            </button>
            <button
              className="btn btn-outline-dark btn-sm m-2"
              onClick={() => setFilter(data)}
            >
              Head Massagers
            </button>
            <button
              className="btn btn-outline-dark btn-sm m-2"
              onClick={() => filterProduct("men's clothing")}
            >
              Blood Circulation & Physiotherapy Machines
            </button>
            <button
              className="btn btn-outline-dark btn-sm m-2"
              onClick={() => filterProduct("women's clothing")}
            >
              Acupressure Products
            </button>
            <button
              className="btn btn-outline-dark btn-sm m-2"
              onClick={() => filterProduct("jewelery")}
            >
              Hot Stone Therapy Products
            </button>
            <button
              className="btn btn-outline-dark btn-sm m-2"
              onClick={() => filterProduct("electronics")}
            >
              Miscellaneous Products
            </button>
          </div>
        </div>



        {/* {filter.map((product) => {
          return (
            <div
              id={product.id}
              key={product.id}
              className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4"
            >
              <div className="card text-center h-100" key={product.id}>
                <img
                  className="card-img-top p-3"
                  src={product.image}
                  alt="Card"
                  height={300}
                />
                <div className="card-body">
                  <h5 className="card-title">
                    {product.title.substring(0, 12)}...
                  </h5>
                  <p className="card-text">
                    {product.description.substring(0, 90)}...
                  </p>
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item lead">$ {product.price}</li>
                
                </ul>
                <div className="card-body">
                  <Link
                    to={"/product/" + product.id}
                    className="btn btn-dark m-1"
                  >
                    Buy Now
                  </Link>
                  <button
                    className="btn btn-dark m-1"
                    onClick={() => {
                      toast.success("Added to cart");
                      addProduct(product);
                    }}
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          );
        })} */}


        <div className="product-card">
          <img src={"https://www.bodycurehealth.com/uploads/product/thumbnail/fdac86ce903b6fff380428be785c48db.jpg"} alt={"title"} className="product-image" />
          <div className="product-info">
            <h2 className="product-title">{"title"}</h2>
            <p className="product-description">{"description"}</p>
            <span className="product-price">{"price"}</span>
          </div>
        </div>
        <div className="product-card">
          <img src={"https://www.bodycurehealth.com/uploads/product/thumbnail/eab085664f44be454a231d791c546daf.jpg"} alt={"title"} className="product-image" />
          <div className="product-info">
            <h2 className="product-title">{"title"}</h2>
            <p className="product-description">{"description"}</p>
            <span className="product-price">{"price"}</span>
          </div>
        </div>
        <div className="product-card">
          <img src={"https://www.bodycurehealth.com/uploads/product/thumbnail/51feb0d95f8e240ac21edaf108b7ae44.jpg"} alt={"title"} className="product-image" />
          <div className="product-info">
            <h2 className="product-title">{"title"}</h2>
            <p className="product-description">{"description"}</p>
            <span className="product-price">{"price"}</span>
          </div>
        </div>
        <div className="product-card">
          <img src={"https://www.bodycurehealth.com/uploads/product/thumbnail/0913eb74a80d12aba50f577c4f6e27af.jpg"} alt={"title"} className="product-image" />
          <div className="product-info">
            <h2 className="product-title">{"title"}</h2>
            <p className="product-description">{"description"}</p>
            <span className="product-price">{"price"}</span>
          </div>
        </div>
        <div className="product-card">
          <img src={"https://www.bodycurehealth.com/uploads/product/thumbnail/0913eb74a80d12aba50f577c4f6e27af.jpg"} alt={"title"} className="product-image" />
          <div className="product-info">
            <h2 className="product-title">{"title"}</h2>
            <p className="product-description">{"description"}</p>
            <span className="product-price">{"price"}</span>
          </div>
        </div>
        <div className="product-card">
          <img src={"https://www.bodycurehealth.com/uploads/product/thumbnail/0913eb74a80d12aba50f577c4f6e27af.jpg"} alt={"title"} className="product-image" />
          <div className="product-info">
            <h2 className="product-title">{"title"}</h2>
            <p className="product-description">{"description"}</p>
            <span className="product-price">{"price"}</span>
          </div>
        </div>
        <div className="product-card">
          <img src={"https://www.bodycurehealth.com/uploads/product/thumbnail/eab085664f44be454a231d791c546daf.jpg"} alt={"title"} className="product-image" />
          <div className="product-info">
            <h2 className="product-title">{"title"}</h2>
            <p className="product-description">{"description"}</p>
            <span className="product-price">{"price"}</span>
          </div>
        </div>
        <div className="product-card">
          <img src={"https://www.bodycurehealth.com/uploads/product/thumbnail/51feb0d95f8e240ac21edaf108b7ae44.jpg"} alt={"title"} className="product-image" />
          <div className="product-info">
            <h2 className="product-title">{"title"}</h2>
            <p className="product-description">{"description"}</p>
            <span className="product-price">{"price"}</span>
          </div>
        </div>


      </>
    );
  };
  return (
    <>

      <div className="container my-3 py-3">
        <div className="row">
          <div className="col-12">
            <h2 className="display-5 text-center latest_update">Latest Products</h2>
            <hr />
          </div>

        </div>
        <div className="row justify-content-center">
          {loading ? <Loading /> : <ShowProducts />}
        </div>
      </div>
      <div className="container products_img mb-5">
        <div className="row" >
        <div className="col-md-6 align-items-center justify-content-center mt-5">
    <h1 style={{ color: "#C5D493" }}><strong>iRest A356-2 Massage Chair</strong></h1>

    <h2 className="mt-4">Features</h2>
    <ul className="list-unstyled">
        <li><strong>28 Whole Body Airbags:</strong> Provides a comprehensive full-body massage.</li>
        <li><strong>Body Length Detection:</strong> Automatically adjusts to fit your body shape perfectly.</li>
       
        <li><strong>Three-Segment L-Shaped Guide Rail:</strong> Adapts dynamically to your body during the massage.</li>
        <li><strong>Five Massage Techniques:</strong> Offers kneading, tapping, shiatsu, rolling, and vibration.</li>
        <li><strong>AI Voice Control:</strong> Operate the chair effortlessly with voice commands.</li>
        <li><strong>Space-Saving Design:</strong> Slides forward for compact placement and zero-gravity comfort.</li>
        <li><strong>Telescopic Footrest:</strong> Adjustable for optimal foot massage.</li>
        <li><strong>Sole Acupoint Roller Massage:</strong> Targets feet with heel scraping and acupoint massage.</li>
    </ul>

    
  
</div>
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <img src={bed} width={"90%"} alt="" />
          </div>
        </div>
      </div>
      <div className="shipping">
        <div className="container">
          <section id="feature" className="section-p1">
            <div className="fe-box">
              <img src="https://i.postimg.cc/PrN2Y6Cv/f1.png" alt="" />
              <h6>Fast Shipping</h6>
            </div>
            <div className="fe-box">
              <img src="https://i.postimg.cc/qvycxW4q/f2.png" alt="" />
              <h6>Online Order</h6>
            </div>
            <div className="fe-box">
              <img src="https://i.postimg.cc/1Rdphyz4/f3.png" alt="" />
              <h6>Save Money</h6>
            </div>
            <div className="fe-box">
              <img src="https://i.postimg.cc/GpYc2JFZ/f4.png" alt="" />
              <h6>Promotions</h6>
            </div>
            <div className="fe-box">
              <img src="https://i.postimg.cc/4yFCwmv6/f5.png" alt="" />
              <h6>Happy Sell</h6>
            </div>
            <div className="fe-box">
              <img src="https://i.postimg.cc/gJN1knTC/f6.png" alt="" />
              <h6>F24/7 Support</h6>
            </div>
          </section>
        </div>
      </div>
    

    </>
  );
};

export default Products;
