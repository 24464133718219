import React from "react";
import { Footer, Navbar } from "../components";
const ContactPage = () => {
  return (
    <>
      <Navbar />
      {/* <div className="container my-3 py-3">
        <h1 className="text-center">Contact Us</h1>
        <hr />
        <div class="row my-4 h-100">
          <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
            <form>
              <div class="form my-3">
                <label for="Name">Name</label>
                <input
                  type="email"
                  class="form-control"
                  id="Name"
                  placeholder="Enter your name"
                />
              </div>
              <div class="form my-3">
                <label for="Email">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="Email"
                  placeholder="name@example.com"
                />
              </div>
              <div class="form  my-3">
                <label for="Password">Message</label>
                <textarea
                  rows={5}
                  class="form-control"
                  id="Password"
                  placeholder="Enter your message"
                />
              </div>
              <div className="text-center">
                <button
                  class="my-2 px-4 mx-auto btn btn-dark"
                  type="submit"
                  disabled
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div> */}
      <>
  <section className="contact_us">
    <div className="container">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <div className="contact_inner">
            <div className="row">
              <div className="col-md-10">
                <div className="contact_form_inner">
                  <div className="contact_field">
                    <h3>Contatc Us</h3>
                    <p>
                      Feel Free to contact us any time. We will get back to you
                      as soon as we can!.
                    </p>
                    <input
                      type="text"
                      className="form-control form-group"
                      placeholder="Name"
                    />
                    <input
                      type="text"
                      className="form-control form-group"
                      placeholder="Email"
                    />
                    <textarea
                      className="form-control form-group"
                      placeholder="Message"
                      defaultValue={""}
                    />
                    <button className="contact_form_submit">Send</button>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="right_conatct_social_icon d-flex align-items-end">
                  <div className="socil_item_inner d-flex">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-square" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact_info_sec">
              <h4>Contact Info</h4>
              <div className="d-flex info_single align-items-center">
                <i className="fas fa-headset" />
                <span>+91 8009 054294</span>
              </div>
              <div className="d-flex info_single align-items-center">
                <i className="fas fa-envelope-open-text" />
                <span>info@flightmantra.com</span>
              </div>
              <div className="d-flex info_single align-items-center">
                <i className="fas fa-map-marked-alt" />
                <span>
                  1000+ Travel partners and 65+ Service city across India, USA,
                  Canada &amp; UAE
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="map_sec">
    <div className="container">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <div className="map_inner">
            <h4>Find Us on Google Map</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore
              quo beatae quasi assumenda, expedita aliquam minima tenetur
              maiores neque incidunt repellat aut voluptas hic dolorem sequi ab
              porro, quia error.
            </p>
            <div className="map_bind">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1779.0268625354!2d75.744729!3d26.90179!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc0967e63343f9b2f!2sBodyCure%20Acupressure%20Massagers%20-%20Electronic%20Massage%20Chairs%20%26%20Beds!5e0!3m2!1sen!2sin!4v1651127313658!5m2!1sen!2sin"
                width="100%"
                height={450}
                frameBorder={0}
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex={0}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

      <Footer />
    </>
  );
};

export default ContactPage;
