// src/components/AddProductPage.js
import React, { useState } from 'react';
import './Seller_profile.css';

const Seller_product = () => {
    const [formData, setFormData] = useState({
        productName: '',
        price: '',
        category: '',
        imageUrl: '',
        configurations: {
            color: '',
            size: ''
        }
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleConfigChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            configurations: {
                ...formData.configurations,
                [name]: value,
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('New Product Added:', formData);
        // Handle API submission here
    };

    return (
        <div className="add-product-page">
            <h1 className="page-title">Add New Product</h1>
            <form className="product-form" onSubmit={handleSubmit}>
                {/* Product Name */}
                <div className="form-group">
                    <label htmlFor="productName" className="form-label">Product Name</label>
                    <input
                        type="text"
                        id="productName"
                        name="productName"
                        value={formData.productName}
                        onChange={handleChange}
                        className="form-input"
                        placeholder="Enter product name"
                        required
                    />
                </div>

                {/* Price */}
                <div className="form-group">
                    <label htmlFor="price" className="form-label">Price ($)</label>
                    <input
                        type="number"
                        id="price"
                        name="price"
                        value={formData.price}
                        onChange={handleChange}
                        className="form-input"
                        placeholder="Enter product price"
                        required
                    />
                </div>

                {/* Category */}
                <div className="form-group">
                    <label htmlFor="category" className="form-label">Category</label>
                    <input
                        type="text"
                        id="category"
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        className="form-input"
                        placeholder="Enter product category"
                        required
                    />
                </div>

                {/* Configurations */}
                <div className="configurations-section">
                    <h2 className="section-title">Configurations</h2>

                    {/* Color */}
                    <div className="form-group">
                        <label htmlFor="color" className="form-label">Color</label>
                        <input
                            type="text"
                            id="color"
                            name="color"
                            value={formData.configurations.color}
                            onChange={handleConfigChange}
                            className="form-input"
                            placeholder="Enter color"
                        />
                    </div>

                    {/* Size */}
                    <div className="form-group">
                        <label htmlFor="size" className="form-label">Size</label>
                        <input
                            type="text"
                            id="size"
                            name="size"
                            value={formData.configurations.size}
                            onChange={handleConfigChange}
                            className="form-input"
                            placeholder="Enter size"
                        />
                    </div>
                </div>

                {/* Image URL */}
                <div className="form-group">
                    <label htmlFor="imageUrl" className="form-label">Product Image URL</label>
                    <input
                        type="text"
                        id="imageUrl"
                        name="imageUrl"
                        value={formData.imageUrl}
                        onChange={handleChange}
                        className="form-input"
                        placeholder="Enter image URL"
                    />
                </div>

                {/* Image Preview */}
                <div className="image-preview">
                    <img
                        src={formData.imageUrl || 'https://via.placeholder.com/300'}
                        alt="Product Preview"
                        className="image-preview-display"
                    />
                </div>

                {/* Submit Button */}
                <button type="submit" className="form-submit-btn">Add Product</button>
            </form>
        </div>
    );
};

export default Seller_product;
